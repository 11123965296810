import React, { useState, useRef } from "react";
import { Form } from "react-final-form";
import { useQuery } from "@tanstack/react-query";
import { useReactToPrint } from "react-to-print";

import { getActiveVisitReservation } from "../../../services/ActiveVisitService";
import styles from "./ModalDetailReservation.module.css";

import Modal from "../Modal";
import Spinner from "../Spinner";
import Button from "../Button";
import Tabs from "../Tabs";
import TextStatusReservation from "../TextStatusReservation";
import { ExistingPatient, FormNewPatient } from "../CalendarScheduleDoctor";
import { Gender, colorsAllergys } from "../../../utils/constants";
import { formatOptionsToArray } from "../../../utils/utils";
import PillItem from "../PillItem";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import usePatientReservationValidation from "../../../utils/validation/usePatientReservationValidation";
import { useHistory } from "react-router-dom";
import { useModal } from "../../../context/ModalProvider";
import FormPayment from "../FormPayment";
import FormPaymentCorrection from "../FormPaymentCorrection";
import WrapperContent from "../WrapperContent";
import PrintInvoice from "../PrintInvoice";
import AsyncSelect from "../AsyncSelect";
import useLocation from "../../../hooks/SatuSehat/useLocation";

export default function ModalDetailReservation({
  businessId = null,
  show,
  handleModal,
  loading = false,
  isPaymentSection = false,
  dataDetailReservation,
  selectedExistingPatient,
  dataFormNew = {},
  handleChangeForm = () => undefined,
  handleChangeStatusReservation = () => undefined,
  setSelectedExistingPatient = () => undefined,
  handleChangePatientReservation = () => undefined,
  handleSubmitNew = () => undefined,
  handleModalStartVisit = () => undefined,
  handleModalEndVisit = () => undefined,
  handleClickPayment = () => undefined,
  handleStartVisit = () => undefined,
}) {
  const { dataLocations } = useLocation();
  const history = useHistory();
  const modal = useModal();
  const validate = usePatientReservationValidation();
  const [detailPatient, setDetailPatient] = useState(null);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setIsLoadingPrint(!isLoadingPrint);
      const printStyle = document.createElement("style");
      printStyle.innerHTML = `
        @media print {
          @page {
            size: A4 portrait; 
          }
        }
      `;
      document.head.appendChild(printStyle);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    onAfterPrint: () => {
      setIsLoadingPrint(false);
      const styleTag = document.querySelector("style");
      if (styleTag) {
        document.head.removeChild(styleTag);
      }
    },
  });

  const { isLoadingSingle } = useQuery(
    ["DetailPatientReservation", dataDetailReservation.patientId],
    () => getActiveVisitReservation(dataDetailReservation.patientId),
    {
      enabled: !!dataDetailReservation.patientId,
      onSuccess: (res) => {
        if (res.status === 200) {
          const response = res.data;
          setDetailPatient(response);
        }
      },
    }
  );

  const localize = useLocalization();
  const columnsExisting = [
    { label: "", style: { fontSize: 14 } },
    { label: localize.getText("noRm"), style: { fontSize: 14 } },
    { label: localize.getText("name"), style: { fontSize: 14 } },
    {
      label: localize.getText("alergies"),
      style: { width: "200px", fontSize: 14 },
    },
    { label: localize.getText("phoneNumber"), style: { fontSize: 14 } },
    { label: localize.getText("dateOfBirth"), style: { fontSize: 14 } },
    { label: localize.getText("gender"), style: { fontSize: 14 } },
    {
      label: localize.getText("address"),
      style: { fontSize: 14 },
    },
  ];

  const gender = Gender().find(
    (value) => value.id === dataDetailReservation.gender
  )?.label;
  const selectedLocation = dataLocations.find(
    (value) => value.id === dataFormNew.locationId
  )
    ? {
        value: dataLocations.find(
          (value) => value.id === dataFormNew.locationId
        ).id,
        label: dataLocations.find(
          (value) => value.id === dataFormNew.locationId
        ).name,
      }
    : dataFormNew.locationId
    ? dataFormNew.locationId
    : null;

  const genderPatient =
    Gender().find((value) => value.id === detailPatient?.gender)?.label || "-";
  const isCancel = dataDetailReservation.status.label === "Batal";
  const isActive = dataDetailReservation.status.label === "Aktif";
  const isDone = dataDetailReservation.status.label === "Selesai";
  const isReservation = dataDetailReservation.status.label === "Reservasi";
  const isPatientNew = dataDetailReservation?.is_patient_new || false;

  const ActionDetailReservation = ({
    handleClickSave = () => undefined,
    isNew = false,
    disableSave = false,
    disabledVisit = false,
  }) => {
    return (
      <div className="d-flex justify-content-end gap-2 flex-wrap">
        <div style={{ display: "none" }}>
          <PrintInvoice
            ref={componentRef}
            dataDetailReservation={dataDetailReservation}
            noRm={detailPatient?.medicalRecordNumber}
          />
        </div>
        <Button
          label="Tutup"
          variant="outline-primary"
          onClick={() => handleModal(!show)}
        />
        {(isDone || isActive) && (
          <Button
            label={localize.getText("printInvoice")}
            className="px-3"
            leftIcon={<i className="far fa-file-alt"></i>}
            variant="warning"
            loading={isLoadingPrint}
            onClick={handlePrint}
          />
        )}
        {dataDetailReservation.status.label === "Reservasi" && (
          <>
            <Button
              label="Batalkan"
              variant="danger"
              onClick={() => {
                modal.showConfirmation({
                  title: "Apakah anda yakin?",
                  message: "Data yang sudah dibatalkan tidak bisa dikembalikan",
                  onConfirm: () =>
                    handleChangeStatusReservation({
                      booking_id: dataDetailReservation.id,
                      status: "CANCELLED",
                    }),
                });
              }}
            />
            {!dataDetailReservation.patientId && (
              <Button
                label="Simpan"
                type={isNew ? "submit" : "button"}
                onClick={handleClickSave}
                disabled={disableSave}
              />
            )}
            {!isCancel && (
              <Button
                label={
                  dataDetailReservation.patientId
                    ? "Start Visit"
                    : "Simpan dan Mulai"
                }
                disabled={disabledVisit}
                variant="success"
                onClick={() => {
                  handleStartVisit({
                    bookingId: dataDetailReservation.id,
                    patientId: !isNew
                      ? selectedExistingPatient?.id ||
                        dataDetailReservation?.patientId ||
                        null
                      : null,
                    staffId: dataDetailReservation.doctorId,
                    identificationNumber: !isNew
                      ? selectedExistingPatient?.identificationNumber ||
                        detailPatient?.identificationNumber ||
                        dataDetailReservation.identityNumber
                      : dataFormNew.identificationNumber,
                    isPatientNew: isNew,
                  });
                }}
              />
            )}
          </>
        )}

        {dataDetailReservation.status.label === "Aktif" &&
          !isPaymentSection && (
            <>
              <Button
                leftIcon={<i class="fas fa-file-alt"></i>}
                label={localize.getText("assesment")}
                variant="success"
                onClick={() =>
                  history.push({
                    pathname: "/active-visit/assesment",
                    search: `?id=${detailPatient?.activeVisitId}&patientId=${detailPatient?.id}`,
                  })
                }
              />
              <Button
                label={localize.getText("endVisit")}
                variant="danger"
                onClick={() =>
                  handleModalEndVisit({
                    patientId: detailPatient?.id,
                    visitCompleted: detailPatient?.visitCompleted,
                    bookingId: dataDetailReservation.id,
                  })
                }
              />
            </>
          )}
      </div>
    );
  };

  return (
    <Modal
      visible={!!show}
      title={isPaymentSection ? "Detail Pembayaran" : "Detail Reservasi"}
      handleHideModal={() => handleModal(null)}
      classNameWrapper="d-flex flex-column gap-4"
      size="lg"
      dialogClassName="max-w-1200"
    >
      <Spinner loading={loading}>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-3">
                <i className="far fa-clock h4 mb-0"></i>
                <p className="mb-0 font-w500">
                  {dataDetailReservation.reservationDate},{" "}
                  {dataDetailReservation.reservationTime}
                </p>
              </div>
              <p className="mb-0 font-w500">
                Durasi {dataDetailReservation.durationVisit} menit
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center gap-4">
              <div className="d-flex align-items-center gap-3">
                <i className="fas fa-user-md h4 font-w900 mb-0"></i>
                <p className="mb-0 font-w500">
                  Dokter {dataDetailReservation.doctorName}
                </p>
              </div>
              {isReservation || !dataDetailReservation.patientId ? (
                <AsyncSelect
                  options={dataLocations}
                  placeholder={localize.getText("placeholderField", {
                    fieldName: localize.getText("location"),
                  })}
                  onChange={(value) => handleChangeForm("locationId", value)}
                  value={selectedLocation}
                  labelKey="name"
                  valueKey="id"
                  disabled={!isReservation}
                />
              ) : (
                <div className="d-flex align-items-center gap-3">
                  <i className="far fa-hospital"></i>
                  <p className="mb-0 font-w500">
                    Ruangan {selectedLocation?.label || "-"}
                  </p>
                </div>
              )}
            </div>
          </div>

          {!dataDetailReservation.patientId && (
            <div className="d-flex flex-column gap-4">
              <div
                className="py-2"
                style={{ borderBottom: "1px solid #E5E5E5" }}
              >
                <div className="px-3 d-flex align-items-center justify-content-between">
                  <p className="mb-0 font-w500">Data User</p>
                  <TextStatusReservation
                    color={dataDetailReservation.status.color}
                    label={dataDetailReservation.status.label}
                  />
                </div>
              </div>
              <div className={styles.wrapperTitleInfo}>
                <div className="d-flex gap-5">
                  <div className="d-flex flex-column gap-2">
                    <p className="m-0 font-w500">
                      {localize.getText("sequenceNumber")}
                    </p>
                    <p className="m-0 font-w500">
                      {localize.getText("identityNumber")}
                    </p>
                    <p className="m-0 font-w500">{localize.getText("name")}</p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <p className="m-0 font-w500">
                      : {dataDetailReservation.noSequence}
                    </p>
                    <p className="m-0 font-w500">
                      : {dataDetailReservation.identityNumber}
                    </p>
                    <p className="m-0 font-w500">
                      : {dataDetailReservation.name}
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-5">
                  <div className="d-flex flex-column gap-2">
                    <p className="m-0 font-w500">
                      {localize.getText("phoneNumber")}
                    </p>
                    <p className="m-0 font-w500">
                      {localize.getText("dateOfBirth")}
                    </p>
                    <p className="m-0 font-w500">
                      {localize.getText("gender")}
                    </p>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <p className="m-0 font-w500">
                      : {dataDetailReservation.phoneNumber}
                    </p>
                    <p className="m-0 font-w500">
                      : {dataDetailReservation.dateOfBirth}
                    </p>
                    <p className="m-0 font-w500">: {gender}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isCancel && (
            <>
              {dataDetailReservation.patientId || isLoadingSingle ? (
                <Spinner loading={isLoadingSingle}>
                  <div className="d-flex flex-column gap-5">
                    <WrapperContent
                      title="Data Pasien"
                      extraHeader={
                        dataDetailReservation.patientId && (
                          <TextStatusReservation
                            color={dataDetailReservation.status.color}
                            label={dataDetailReservation.status.label}
                          />
                        )
                      }
                    >
                      <div className={styles.wrapperTitleInfo}>
                        <div className="d-flex gap-5">
                          <div className="d-flex flex-column gap-2">
                            <p className="m-0 font-w500">
                              {localize.getText("sequenceNumber")}
                            </p>
                            <p className="m-0 font-w500">
                              {localize.getText("satuSehat") +
                                " - " +
                                localize.getText("patientId")}
                            </p>
                            <p className="m-0 font-w500">
                              {localize.getText("medicalRecord")}
                            </p>
                            <p className="m-0 font-w500">
                              {localize.getText("identityNumber")}
                            </p>
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <p className="m-0 font-w500">
                              : {dataDetailReservation.noSequence}
                            </p>
                            <p className="m-0 font-w500">
                              : {detailPatient?.satuSehatPatientId || "-"}
                            </p>
                            <p className="m-0 font-w500">
                              : {detailPatient?.medicalRecordNumber}
                            </p>
                            <p className="m-0 font-w500">
                              : {detailPatient?.identificationNumber} (
                              {detailPatient?.identificationType})
                            </p>
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="d-flex flex-column gap-2">
                            <p className="m-0 font-w500">
                              {localize.getText("name")}
                            </p>
                            <p className="m-0 font-w500">
                              {localize.getText("dateOfBirth")}
                            </p>
                            <p className="m-0 font-w500">
                              {localize.getText("gender")}
                            </p>
                            <p className="m-0 font-w500">
                              {localize.getText("alergies")}
                            </p>
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <div className="d-flex items-center gap-2">
                              <p className="m-0 font-w500">
                                : {detailPatient?.name}
                              </p>
                              {isPatientNew && (
                                <PillItem
                                  text={localize.getText("patientNew")}
                                  isSmall
                                />
                              )}
                            </div>
                            <p className="m-0 font-w500">
                              : {detailPatient?.dob}
                            </p>
                            <p className="m-0 font-w500">: {genderPatient}</p>
                            <div
                              className="d-flex gap-2 flex-wrap"
                              style={{ maxWidth: 210 }}
                            >
                              <p className="m-0 font-w500">:</p>
                              {formatOptionsToArray(
                                detailPatient?.allergy || []
                              ).length > 0
                                ? formatOptionsToArray(
                                    detailPatient?.allergy
                                  ).map((item, index) => (
                                    <PillItem
                                      text={item}
                                      key={index}
                                      bgColor={
                                        colorsAllergys[
                                          index % colorsAllergys.length
                                        ]
                                      }
                                    />
                                  ))
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </WrapperContent>

                    {(isActive || isDone) && (
                      <>
                        <div className="d-flex flex-column gap-4">
                          <Spinner loading={isLoadingSingle}>
                            <FormPayment
                              onSubmit={handleClickPayment}
                              businessId={businessId}
                              value={dataDetailReservation?.payment || null}
                              disabledForm={isDone}
                            />
                          </Spinner>
                        </div>
                        {isPaymentSection && (
                          <div className="d-flex flex-column gap-4">
                            <Spinner loading={isLoadingSingle}>
                              <FormPaymentCorrection
                                onSubmit={handleClickPayment}
                                businessId={businessId}
                                value={dataDetailReservation?.payment || null}
                              />
                            </Spinner>
                          </div>
                        )}
                      </>
                    )}

                    <ActionDetailReservation />
                  </div>
                </Spinner>
              ) : !isLoadingSingle && !loading ? (
                <Tabs
                  items={[
                    {
                      label: "Pasien Lama",
                      key: "1",
                      children: (
                        <div className="d-flex flex-column gap-4">
                          <ExistingPatient
                            defaultForm={{
                              name: dataDetailReservation.name,
                              phoneNumber: dataDetailReservation.phoneNumber,
                              date: dataDetailReservation.dateOfBirth,
                            }}
                            columnsExisting={columnsExisting}
                            selectedExistingPatient={selectedExistingPatient}
                            setSelectedExistingPatient={
                              setSelectedExistingPatient
                            }
                          />
                          <ActionDetailReservation
                            handleClickSave={handleChangePatientReservation}
                            disableSave={!selectedExistingPatient?.id}
                            disabledVisit={
                              !dataDetailReservation.patientId
                                ? !selectedExistingPatient?.id
                                : false
                            }
                          />
                        </div>
                      ),
                    },
                    {
                      label: "Pasien Baru",
                      key: "2",
                      children: (
                        <Form
                          initialValues={{ ...dataFormNew }}
                          validate={validate}
                          onSubmit={handleSubmitNew}
                        >
                          {({ handleSubmit, values, valid }) => (
                            <form
                              className="row row-gap-4"
                              style={{ rowGap: 12 }}
                              onSubmit={handleSubmit}
                            >
                              <FormNewPatient
                                value={dataFormNew}
                                handleChangeForm={handleChangeForm}
                              />
                              <ActionDetailReservation
                                isNew
                                disabledVisit={!valid}
                              />
                            </form>
                          )}
                        </Form>
                      ),
                    },
                  ]}
                />
              ) : null}
            </>
          )}

          {isCancel && <ActionDetailReservation />}
        </div>
      </Spinner>
    </Modal>
  );
}
