import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Card, Col, Row } from "react-bootstrap";
import {
  getBusinessProfile,
  postBusinessProfile,
} from "../../../services/BusinessService";
import {
  getServicesType,
  getBusinessType,
} from "../../../services/GeneralService";

import { useLocalization } from "../../../context/LocalizeContextProvider";
import { useNotification } from "../../../context/NotificationProvider";
import TitlePage from "../../components/TitlePage";
import InputField from "../../components/InputField";
import CheckboxField from "../../components/CheckboxField";
import AsyncSelect from "../../components/AsyncSelect";
import Button from "../../components/Button";
import UploadImageAvatar from "../../components/UploadImageAvatar";
import LoadingSection from "../../components/LoadingSection";
import { retrieveUrlFile } from "../../../utils/utils";
import PrimaryColorField from "../../components/PrimaryColorField";

export default function BusinessProfile() {
  const localize = useLocalization();
  const notification = useNotification();
  const [dataServices, setDataServices] = useState([]);
  const [dataBusiness, setDataBusiness] = useState([]);
  const [dataProfile, setDataProfile] = useState({
    businessName: "",
    businessType: null,
    businessService: [],
    businessAddress: "",
    businessLogo: null,
    businessImage: null,
    published: false,
    primaryColor: "",
  });

  const { data: dataServicesType } = useQuery(
    ["ServicesType"],
    getServicesType,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: dataBusinessType } = useQuery(
    ["BusinessType"],
    getBusinessType,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    isLoading,
    refetch,
    data: dataProfileFetch,
  } = useQuery(["BusinessProfile"], getBusinessProfile, {
    onSuccess: (res) => {
      if (res.data && res.status === 200) {
        const response = res.data;
        setDataProfile({
          businessName: response?.name,
          businessType: {
            label: response?.businessType.name,
            value: response?.businessType.id,
          },
          businessService: [],
          businessAddress: response?.address,
          businessLogo: response?.logo,
          businessImage: response?.mainPhoto,
          published: response?.published,
          primaryColor: response?.primaryColor,
        });
        const body = document.querySelector("body");
        body.setAttribute("data-primary", response?.primaryColor);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingSubmit, mutate: mutateSubmit } = useMutation(
    postBusinessProfile,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successAddData"));
          refetch();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: (res) => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  useEffect(() => {
    if (dataServicesType && dataServicesType.data.length > 0) {
      const data = [];
      dataServicesType.data.forEach((item) => {
        data.push({
          value: item.id,
          label: item.name,
        });
      });
      setDataServices(data);
    }
  }, [dataServicesType]);

  useEffect(() => {
    if (dataBusinessType && dataBusinessType.data.length > 0) {
      const data = [];
      dataBusinessType.data.forEach((item) => {
        data.push({
          value: item.id,
          label: item.name,
        });
      });
      setDataBusiness(data);
    }
  }, [dataBusinessType]);

  useEffect(() => {
    if (dataProfileFetch && dataServices.length > 0) {
      const data = dataProfileFetch.data;
      handleChangeField(
        "businessService",
        dataServices.filter((item) => data.serviceIds.includes(item.value))
      );
    }
  }, [dataProfileFetch, dataServices]);

  if (isLoading) {
    return <LoadingSection />;
  }

  const handleChangeField = (name, value) => {
    setDataProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (values) => {
    let logoUrl = values.businessLogo;
    let imageUrl = values.businessImage;
    const serviceIds = [];

    if (values.businessLogo && values.businessLogo.constructor === File) {
      logoUrl = await retrieveUrlFile(values.businessLogo);
    }
    if (values.businessImage && values.businessImage.constructor === File) {
      imageUrl = await retrieveUrlFile(values.businessImage);
    }
    values?.businessService?.forEach((item) => {
      serviceIds.push(item.value);
    });

    const data = {
      name: values.businessName,
      address: values.businessAddress,
      logo: logoUrl,
      mainPhoto: imageUrl,
      businessTypeId: values.businessType.value,
      serviceIds,
      published: values.published,
      primaryColor: values.primaryColor,
    };

    mutateSubmit(data);
  };

  return (
    <Form
      initialValues={{
        businessName: dataProfile?.businessName,
        businessType: dataProfile?.businessType,
        businessService: dataProfile?.businessService,
        businessAddress: dataProfile?.businessAddress,
        businessLogo: dataProfile?.businessLogo,
        businessImage: dataProfile?.businessImage,
        published: dataProfile?.published,
        primaryColor: dataProfile?.primaryColor,
      }}
      validate={(values) => {
        const errors = {};

        if (!values.businessName) {
          errors.businessName = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("businessName") }
          );
        }
        if (!values.businessType) {
          errors.businessType = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("businessType") }
          );
        }
        if (values.businessService.length === 0) {
          errors.businessService = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("businessService") }
          );
        }
        if (!values.businessAddress) {
          errors.businessAddress = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("businessAddress") }
          );
        }
        if (!values.businessLogo) {
          errors.businessLogo = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("businessLogo") }
          );
        }
        if (!values.businessImage) {
          errors.businessImage = localize.getText(
            "generalRequiredFieldValidation",
            { fieldName: localize.getText("businessImage") }
          );
        }

        return errors;
      }}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit }) => (
        <Row>
          <Col md={8}>
            <Card>
              <Card.Body>
                <TitlePage text="Informasi Umum" />
                <form className="py-4 d-flex flex-column gap-3">
                  <Field name="businessName">
                    {({ input, meta }) => (
                      <InputField
                        label={localize.getText("businessName")}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("businessName"),
                        })}
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeField("businessName", value)
                        }
                        onBlur={input.onBlur}
                        value={input.value}
                      />
                    )}
                  </Field>
                  <Field name="businessType">
                    {({ input, meta }) => (
                      <AsyncSelect
                        label={localize.getText("businessType")}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("businessType"),
                        })}
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeField("businessType", value)
                        }
                        onBlur={input.onBlur}
                        value={input.value}
                        options={dataBusiness}
                        isLoading={false}
                      />
                    )}
                  </Field>
                  <Field name="businessService">
                    {({ input, meta }) => (
                      <AsyncSelect
                        label={localize.getText("businessService")}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("businessService"),
                        })}
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeField("businessService", value)
                        }
                        onBlur={input.onBlur}
                        value={input.value}
                        options={dataServices}
                        isMulti
                        isLoading={false}
                      />
                    )}
                  </Field>
                  <Field name="businessAddress">
                    {({ input, meta }) => (
                      <InputField
                        label={localize.getText("businessAddress")}
                        placeholder={localize.getText("placeholderField", {
                          fieldName: localize.getText("businessAddress"),
                        })}
                        type="textarea"
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeField("businessAddress", value)
                        }
                        onBlur={input.onBlur}
                        value={input.value}
                      />
                    )}
                  </Field>
                  <Field name="primaryColor">
                    {({ input }) => (
                      <PrimaryColorField
                        label="Warna Primary"
                        value={input.value}
                        onChange={(value) =>
                          handleChangeField("primaryColor", value)
                        }
                      />
                    )}
                  </Field>
                  <Field name="published">
                    {({ input }) => (
                      <CheckboxField
                        id="published-bussines"
                        label="Aktifkan fitur reservasi online"
                        value={input.value}
                        onChange={(value) =>
                          handleChangeField("published", value)
                        }
                      />
                    )}
                  </Field>
                  <Button
                    label={localize.getText("save")}
                    className="mt-3"
                    onClick={handleSubmit}
                    loading={isLoadingSubmit}
                  />
                </form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Body className="d-flex flex-column gap-4">
                <TitlePage text="Media Klinik" />
                <div className="d-flex justify-content-center flex-column gap-5 align-items-center">
                  <Field name="businessLogo">
                    {({ input, meta }) => (
                      <UploadImageAvatar
                        label="Logo Klinik"
                        defaultImages="https://dummyimage.com/400x400/F6F6F6/000"
                        id="logo-clinic"
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeField("businessLogo", value)
                        }
                      />
                    )}
                  </Field>
                  <Field name="businessImage">
                    {({ input, meta }) => (
                      <UploadImageAvatar
                        label="Banner Klinik"
                        defaultImages="https://dummyimage.com/600x400/F6F6F6/000"
                        style={{ width: "100%" }}
                        id="banner-clinic"
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={(value) =>
                          handleChangeField("businessImage", value)
                        }
                      />
                    )}
                  </Field>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Form>
  );
}
