import React, { useState } from "react";
import ReservationList from "../components";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNotification } from "../../../../context/NotificationProvider";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import {
  getReservations,
  getDetailReservation,
  updateReservation,
  postBookingReservation,
  postNewPatientReservation,
  postStartVisitReservation,
  endVisitReservation,
} from "../../../../services/Handlers/ReservationService";
import { useAuth } from "../../../../context/AuthProvider";
import { useHome } from "../../../../context/HomeProvider";
import moment from "moment";
import {
  getTimeSlotOneHourReservationFormat,
  getFilteredTime,
  formatTimeSlots,
  retrieveUrlFile,
} from "../../../../utils/utils";
import { roleUser, statusReservation } from "../../../../utils/constants";
import { useModal } from "../../../../context/ModalProvider";
import { postSavePayment } from "../../../../services/Handlers/PaymentService";

export const defaultDetailReservation = {
  id: null,
  user_id: null,
  patientId: null,
  doctorId: null,
  noRm: null,
  allergies: null,
  reservationDate: null,
  reservationTime: null,
  durationVisit: null,
  doctorName: "",
  status: "",
  noSequence: null,
  identityNumber: null,
  identityType: null,
  name: "",
  phoneNumber: null,
  dateOfBirth: null,
  gender: null,
  address: null,
  payment: null,
  is_patient_new: false,
};

export default function Reservation() {
  const deafultDataReservationNew = {
    durationVisit: null,
    availableSlot: [],
    reservationDate: null,
    doctorId: null,
    doctorName: null,
  };
  const defaultDataFormPatientNew = {
    identificationType: "KTP",
    medicalRecordNumber: null,
    serviceId: null,
    locationId: null,
    timeVisit: [],
    identificationNumber: "",
    name: "",
    email: "",
    gender: null,
    dateOfBirth: null,
    phoneNumber: null,
    allergies: [],
    remark: "",
    address: "",
  };
  const notification = useNotification();
  const localize = useLocalization();
  const modal = useModal();
  const { userDetail, role } = useAuth();
  const { dataDoctors, dataServices } = useHome();
  const [reservationDate, setReservationDate] = useState(moment());
  const [dataReservation, setDataReservation] = useState([]);
  const [dataDetailReservation, setDataDetailReservation] = useState(
    defaultDetailReservation
  );
  const [dataReservationNew, setDataReservationNew] = useState(
    deafultDataReservationNew
  );
  const [dataFormPatientNew, setDataFormPatientNew] = useState(
    defaultDataFormPatientNew
  );
  const [selectedExistingPatient, setSelectedExistingPatient] = useState(null);
  const [showModalDetail, setShowModalDetail] = useState(null);
  const [showModalStartVisit, setShowModalStartVisit] = useState(null);
  const [showModalEndVisit, setShowModalEndVisit] = useState(null);
  const isDoctorId =
    ((role === roleUser.ROLE_DOCTOR || role === roleUser.ROLE_BEAUTICIAN) &&
      userDetail?.id) ||
    null;

  const {
    isLoading: isLoadingSubmitExisting,
    mutate: mutateSubmitReservation,
  } = useMutation(postBookingReservation, {
    onSuccess: (res) => {
      if (res.status === 200 || res.status === 201) {
        refetch();
        notification.success(localize.getText("successAddReservation"));
        handleHideShowModalAdd(null);
      } else {
        notification.error(
          res?.data?.detail || localize.getText("unknownMessageError")
        );
      }
    },
    onError: () => {
      notification.error(localize.getText("unknownMessageError"));
    },
  });

  const { isLoading: isLoadingSubmitPayment, mutate: mutateSubmitPayment } =
    useMutation(postSavePayment, {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          refetch();
          notification.success(localize.getText("successAddPayment"));
          handleClearAllFormModal();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    });

  const { isLoading: isLoadingSubmitNew, mutate: mutateSubmitReservationNew } =
    useMutation(postNewPatientReservation, {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          refetch();
          notification.success(localize.getText("successAddReservation"));
          handleClearAllFormModal();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    });

  const { isLoading: isLoadingDetail, isFetching: isFetchingDetail } = useQuery(
    ["DetailReservation", showModalDetail],
    () => getDetailReservation(showModalDetail),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          if (response) {
            const status = statusReservation.find(
              (item) => item.value === response.status
            );
            const reservationTimeFormat = getFilteredTime(
              response.duration_visit,
              response.time_slots
            )[0];
            const reservationTime =
              moment(
                reservationTimeFormat.availability_start,
                "HH:mm:ss"
              ).format("HH:mm") +
              " - " +
              moment(reservationTimeFormat.availability_end, "HH:mm:ss").format(
                "HH:mm"
              );

            setDataDetailReservation((prevState) => ({
              ...prevState,
              id: response.id,
              patientId: response.patient_id,
              reservationDate: moment(
                response.reservation_date,
                "YYYY-MM-DD"
              ).format("dddd DD MMMM YYYY"),
              reservationTime,
              durationVisit: response.duration_visit,
              doctorName:
                dataDoctors.find((value) => value.id === response.doctor_id)
                  ?.name || "",
              doctorId: response.doctor_id,
              status,
              noSequence: response.no_sequence,
              identityNumber: response.user_identity_number,
              identityType: response.user_identity_type,
              name: response.user_name,
              phoneNumber: response.user_phone_number,
              dateOfBirth: moment(
                response.user_date_of_birth,
                "YYYY-MM-DD"
              ).format("DD MMMM YYYY"),
              gender: response.user_gender,
              payment: response.payment,
              user_id: response.user_id,
              is_patient_new: response?.is_patient_new || false,
            }));
            setDataFormPatientNew((prevState) => ({
              ...prevState,
              identificationType: response.user_identity_type,
              identificationNumber: response.user_identity_number,
              name: response.user_name,
              phoneNumber: response.user_phone_number,
              email: response.user_email,
              dateOfBirth: response.user_date_of_birth,
              gender: response.user_gender,
              locationId: response.location_id,
            }));
          }
        } else {
          notification.error(localize.getText("unknownMessageError"));
        }
      },
      enabled: !!showModalDetail,
    }
  );

  const {
    isLoading: isLoadingList,
    isFetching: isFetchList,
    refetch,
  } = useQuery(
    ["ReservationList", reservationDate, isDoctorId],
    () =>
      getReservations(
        userDetail.businessId,
        reservationDate.format("YYYY-MM-DD"),
        isDoctorId
      ),
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          const response = res.data;
          if (response) {
            if (response.length > 0) {
              const reservation = response.map((item) => {
                return {
                  doctorId: item.doctorId,
                  doctorName:
                    dataDoctors.find((value) => value.id === item.doctorId)
                      ?.name || "",
                  durationVisit: item.durationVisit,
                  reservations: getTimeSlotOneHourReservationFormat(
                    item.doctorSchedule || [],
                    item.reservations || []
                  ),
                };
              });
              setDataReservation(reservation);
            }
          }
        } else {
          notification.error(localize.getText("unknownMessageError"));
        }
      },
      enabled: !!(userDetail?.businessId && dataDoctors.length > 0),
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdateReservation } =
    useMutation(updateReservation, {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          refetch();
          setDataDetailReservation(defaultDetailReservation);
          handleClearAllFormModal();
        } else {
          notification.error(localize.getText("unknownMessageError"));
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    });

  const { isLoading: isLoadingStartVisit, mutate: mutateStartVisit } =
    useMutation(postStartVisitReservation, {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("successStartVisit"));
          handleClearAllFormModal();
          refetch();
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    });

  const { isLoading: isLoadingEndVisit, mutate: mutateEndVisit } = useMutation(
    endVisitReservation,
    {
      onSuccess: (res) => {
        if (res.status === 200 || res.status === 201) {
          notification.success(localize.getText("endVisitSuccess"));
        } else {
          notification.error(
            res?.data?.detail || localize.getText("unknownMessageError")
          );
        }
        handleClearAllFormModal();
        refetch();
      },
      onError: () => {
        notification.error(localize.getText("unknownMessageError"));
      },
    }
  );

  const createReservation = (patientData) => ({
    doctorId: dataReservationNew.doctorId,
    dateVisit: dataReservationNew.reservationDate.format("YYYY-MM-DD"),
    timeVisit: dataFormPatientNew.timeVisit,
    businessId: userDetail.businessId,
    serviceId: dataFormPatientNew.serviceId.value,
    email: null,
    name: patientData.name,
    userDob: moment(patientData.dob, patientData.dobFormat).format(
      "YYYY-MM-DD"
    ),
    userPhoneNumber: patientData.phoneNumber,
    gender: patientData.gender,
    identificationNumber: patientData.identificationNumber,
    identificationType: patientData.identificationType,
    durationVisit: dataReservationNew.durationVisit,
    patientId: patientData.patientId,
    status: "ACTIVE",
  });

  const handleHideShowModalDetial = (value) => {
    if (!value) {
      setDataDetailReservation(defaultDetailReservation);
      handleResetFormPatientNew();
    }
    setShowModalDetail(value);
  };

  const handleHideShowModalAdd = (data) => {
    if (!data) {
      setDataReservationNew(deafultDataReservationNew);
      handleResetFormPatientNew();
      return;
    }
    const availableSlot = formatTimeSlots(data.availableSchedule);
    if (getFilteredTime(data.durationVisit, availableSlot).length === 1) {
      handleChangeForm("timeVisit", availableSlot);
    }
    if (dataServices.length === 1) {
      handleChangeForm("serviceId", {
        label: dataServices[0].name,
        value: dataServices[0].id,
      });
    }
    setDataReservationNew({
      doctorId: data.doctorId,
      doctorName: data.doctorName,
      availableSlot,
      durationVisit: data.durationVisit,
      reservationDate: reservationDate,
    });
  };

  const handleChangeStatusReservation = (data) => {
    mutateUpdateReservation({
      booking_id: data.booking_id,
      status: data.status,
    });
  };

  const handleChangePatientReservation = () => {
    if (!selectedExistingPatient.identificationNumber) {
      notification.error(
        `Pasien ${selectedExistingPatient.name} tidak mempunyai KTP/Paspor`
      );
      return;
    }

    mutateUpdateReservation({
      booking_id: dataDetailReservation.id,
      patient_id: selectedExistingPatient.id,
      location_id: dataFormPatientNew.locationId,
    });
  };

  const handleChangeForm = (name, value) => {
    setDataFormPatientNew((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleResetFormPatientNew = () => {
    setDataFormPatientNew(defaultDataFormPatientNew);
    setSelectedExistingPatient(null);
  };

  const handleSubmitNew = () => {
    const data = {
      ...dataFormPatientNew,
      locationId:
        dataFormPatientNew.locationId?.value || dataFormPatientNew.locationId,
      identificationNumber: dataFormPatientNew.identificationNumber,
      userPhoneNumber: dataFormPatientNew.phoneNumber,
      doctorId: dataReservationNew.doctorId,
      dateVisit: dataReservationNew.reservationDate.format("YYYY-MM-DD"),
      businessId: userDetail.businessId,
      serviceId: dataFormPatientNew.serviceId.value,
      userDob: dataFormPatientNew.dateOfBirth,
      durationVisit: dataReservationNew.durationVisit,
    };

    mutateSubmitReservationNew(data);
  };

  const handleUpdatePatientReservation = () => {
    const data = {
      ...dataFormPatientNew,
      booking_id: dataDetailReservation.id,
      isUpdate: true,
    };

    mutateSubmitReservationNew(data);
  };

  const handleSubmitExisting = () => {
    const data = {
      doctorId: dataReservationNew.doctorId,
      dateVisit: dataReservationNew.reservationDate.format("YYYY-MM-DD"),
      timeVisit: dataFormPatientNew.timeVisit,
      durationVisit: dataReservationNew.durationVisit,
      businessId: userDetail.businessId,
      serviceId: dataFormPatientNew.serviceId.value,
      patientId: selectedExistingPatient.id,
      locationId:
        dataFormPatientNew.locationId?.value || dataFormPatientNew.locationId,
      email: null,
      name: selectedExistingPatient.name,
      userDob: moment(selectedExistingPatient.dob, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      userPhoneNumber: selectedExistingPatient.phoneNumber,
      gender: selectedExistingPatient.gender,
      identificationNumber: selectedExistingPatient.identificationNumber,
      identificationType: selectedExistingPatient.identificationType,
    };

    if (!selectedExistingPatient.identificationNumber) {
      notification.error(
        `Pasien ${selectedExistingPatient.name} tidak mempunyai KTP/Paspor`
      );
      return;
    }

    if (
      selectedExistingPatient.identificationNumber.length !== 16 &&
      selectedExistingPatient.identificationType === "KTP"
    ) {
      notification.error(`Harap masukkan Nomor KTP 16 angka`);
      return;
    }

    mutateSubmitReservation(data);
  };

  const handleClearAllFormModal = () => {
    setDataDetailReservation(defaultDetailReservation);
    setShowModalDetail(null);
    setShowModalStartVisit(null);
    setShowModalEndVisit(null);
    setDataReservationNew(deafultDataReservationNew);
    handleResetFormPatientNew();
    modal.dismiss();
  };

  const handleModalStartVisit = (data) => {
    if (!data) {
      setShowModalStartVisit(null);
      return;
    }
    setShowModalStartVisit({
      bookingId: data.bookingId,
      patientId: data.patientId,
      doctorId: data.doctorId,
      identificationNumber: data.identificationNumber,
      isPatientNew: data.isPatientNew,
    });
  };

  const handleModalEndVisit = (data) => {
    if (!data) {
      setShowModalEndVisit(null);
      return;
    }

    setShowModalEndVisit({
      patientId: data.patientId,
      bookingId: data.bookingId,
      visitCompleted: data.visitCompleted,
    });
  };

  const handleStartVisit = (data) => {
    const alergiesDTOs = dataFormPatientNew.allergies.map((item) => ({
      ...item,
      ...(item.__isNew__ ? {} : { __isNew__: false }),
    }));

    const patientData = !data.bookingId
      ? selectedExistingPatient?.id
        ? {
            name: selectedExistingPatient.name,
            dob: selectedExistingPatient.dob,
            dobFormat: "DD-MM-YYYY", // Explicit format for existing patient
            phoneNumber: selectedExistingPatient.phoneNumber,
            gender: selectedExistingPatient.gender,
            identificationNumber: selectedExistingPatient.identificationNumber,
            identificationType: selectedExistingPatient.identificationType,
            patientId: selectedExistingPatient.id,
          }
        : {
            name: dataFormPatientNew.name,
            dob: dataFormPatientNew.dateOfBirth,
            dobFormat: "YYYY-MM-DD", // Explicit format for new patient
            phoneNumber: dataFormPatientNew.phoneNumber,
            gender: dataFormPatientNew.gender,
            identificationNumber: dataFormPatientNew.identificationNumber,
            identificationType: dataFormPatientNew.identificationType,
            patientId: null,
          }
      : null;

    const patientDTO = data?.isPatientNew
      ? {
          ...dataFormPatientNew,
          alergy: alergiesDTOs,
          dob: moment(dataFormPatientNew.dateOfBirth, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          ),
        }
      : null;

    const dataPatientForm = {
      ...data,
      ...(patientData && { reservation: createReservation(patientData) }),
      ...(patientDTO && { patientDTO }),
      locationId:
        dataFormPatientNew.locationId?.value || dataFormPatientNew.locationId,
    };

    mutateStartVisit(dataPatientForm);
  };

  const handleEndVisit = async (data) => {
    let imageUrl = "";
    if (data.fileUrl) {
      imageUrl = await retrieveUrlFile(data.fileUrl);
    }

    mutateEndVisit({
      note: data?.note,
      fileUrl: imageUrl,
      status: "DONE",
      patient_id: data.patientId,
      booking_id: showModalEndVisit?.bookingId,
    });
  };

  const handleClickPayment = (value) => {
    const data = {
      bookingId: dataDetailReservation.id,
      userId: dataDetailReservation.user_id,
      businessId: userDetail.businessId,
      createdBy: userDetail?.firstName,
      updatedBy: userDetail?.firstName,
      ...value,
    };

    mutateSubmitPayment(data);
  };

  return (
    <ReservationList
      loading={isLoadingList || isFetchList || isLoadingUpdate}
      isLoadingDetail={
        isLoadingDetail ||
        isFetchingDetail ||
        isLoadingSubmitNew ||
        isLoadingUpdate ||
        isLoadingSubmitPayment ||
        isLoadingStartVisit
      }
      isLoadingAdd={
        isLoadingSubmitExisting || isLoadingSubmitNew || isLoadingStartVisit
      }
      isLoadingStartVisit={isLoadingStartVisit}
      isLoadingEndVisit={isLoadingEndVisit}
      dataReservation={dataReservation}
      reservationDate={reservationDate}
      setReservationDate={setReservationDate}
      showModalDetail={showModalDetail}
      businessId={userDetail?.businessId}
      setShowModalAdd={handleHideShowModalAdd}
      setShowModalDetail={handleHideShowModalDetial}
      dataDetailReservation={dataDetailReservation}
      handleChangeStatusReservation={handleChangeStatusReservation}
      dataReservationNew={dataReservationNew}
      dataFormPatientNew={dataFormPatientNew}
      handleChangeForm={handleChangeForm}
      handleSubmitNew={handleSubmitNew}
      handleSubmitExisting={handleSubmitExisting}
      dataServices={dataServices}
      selectedExistingPatient={selectedExistingPatient}
      showModalStartVisit={showModalStartVisit}
      showModalEndVisit={showModalEndVisit}
      setSelectedExistingPatient={setSelectedExistingPatient}
      handleChangePatientReservation={handleChangePatientReservation}
      handleUpdatePatientReservation={handleUpdatePatientReservation}
      handleModalStartVisit={handleModalStartVisit}
      handleModalEndVisit={handleModalEndVisit}
      handleStartVisit={handleStartVisit}
      handleEndVisit={handleEndVisit}
      handleClickPayment={handleClickPayment}
    />
  );
}
