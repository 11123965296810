import React from "react";
import { Form } from "react-final-form";
import moment from "moment";

import Modal from "../Modal";
import Tabs from "../Tabs";
import Button from "../Button";
import InputScheduleField from "../InputScheduleField";
import AsyncSelect from "../AsyncSelect";
import Spinner from "../Spinner";
import { FormNewPatient, ExistingPatient } from "../CalendarScheduleDoctor";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import usePatientReservationValidation from "../../../utils/validation/usePatientReservationValidation";
import useLocation from "../../../hooks/SatuSehat/useLocation";

export default function ModalAddReservation({
  show,
  handleModal,
  data,
  dataFormNew,
  handleChangeForm,
  handleSubmitExisting = () => undefined,
  handleSubmitNew = () => undefined,
  handleModalStartVisit = () => undefined,
  handleStartVisit = () => undefined,
  dataServices,
  loading,
  selectedExistingPatient,
  setSelectedExistingPatient,
}) {
  const localize = useLocalization();
  const validate = usePatientReservationValidation();
  const { dataLocations } = useLocation();

  const columnsExisting = [
    { label: "", style: { fontSize: 14 } },
    { label: localize.getText("noRm"), style: { fontSize: 14 } },
    { label: localize.getText("name"), style: { fontSize: 14, width: "90px" } },
    { label: localize.getText("numberKtp"), style: { width: "200px" } },
    {
      label: localize.getText("alergies"),
      style: { width: "100px" },
    },
    { label: localize.getText("phoneNumber"), style: { fontSize: 14 } },
    { label: localize.getText("dateOfBirth"), style: { fontSize: 14 } },
    { label: localize.getText("gender"), style: { fontSize: 14 } },
    {
      label: localize.getText("address"),
      style: { fontSize: 14 },
    },
  ];

  const ActionAddReservation = ({
    isNew = false,
    handleClickSave = () => undefined,
    disableStartVisit = false,
    disableSave = false,
  }) => {
    return (
      <div className="d-flex justify-content-end gap-2 flex-wrap">
        <Button
          label="Batal"
          variant="outline-primary"
          onClick={() => handleModal(!show)}
        />
        <Button
          label="Simpan"
          onClick={handleClickSave}
          disabled={disableSave}
          type={isNew ? "submit" : "button"}
        />
        <Button
          label="Simpan dan Mulai"
          variant="success"
          disabled={disableStartVisit}
          onClick={() => {
            handleStartVisit({
              patientId: !isNew ? selectedExistingPatient?.id || null : null,
              bookingId: null,
              identificationNumber: !isNew
                ? selectedExistingPatient?.identificationNumber
                : dataFormNew.identificationNumber,
              staffId: data.doctorId,
              isPatientNew: isNew,
            });
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      visible={show}
      title="Tambah Reservasi"
      handleHideModal={() => handleModal(null)}
      classNameWrapper="d-flex flex-column gap-4"
      size="lg"
      dialogClassName="max-w-1200"
    >
      <Spinner loading={loading}>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-3">
                <i className="far fa-clock h4 mb-0"></i>
                <p className="mb-0 font-w500">
                  {moment(data.reservationDate).format("dddd DD MMMM YYYY")}
                </p>
              </div>
              <p className="mb-0 font-w500">
                Durasi {data.durationVisit} menit
              </p>
            </div>
            <div className="d-flex align-items-center gap-3">
              <i className="fas fa-user-md h4 font-w900 mb-0"></i>
              <p className="mb-0 font-w500">Dokter {data.doctorName}</p>
            </div>
          </div>
          <div className="row" style={{ rowGap: 16 }}>
            <div className="col-md-6">
              {data && (
                <InputScheduleField
                  label="Pilih Waktu"
                  labelClass="h5"
                  doctorAvailability={data.availableSlot}
                  doctorId={data?.doctorId}
                  durationVisit={data?.durationVisit}
                  bookingDate={data?.reservationDate}
                  value={dataFormNew.timeVisit || []}
                  onClick={(value) => {
                    handleChangeForm("timeVisit", value);
                  }}
                  noHeader
                  error={
                    dataFormNew.timeVisit.length === 0 && "Harap pilih waktu"
                  }
                />
              )}
            </div>
            <div className="col-md-6 d-flex flex-column gap-3">
              <AsyncSelect
                labelClass="h5"
                label="Pilih Layanan"
                options={dataServices}
                value={dataFormNew.serviceId}
                valueKey="id"
                labelKey="name"
                onChange={(value) => handleChangeForm("serviceId", value)}
                error={!dataFormNew.serviceId && "Harap pilih layanan"}
              />
              <AsyncSelect
                label={localize.getText("location")}
                options={dataLocations}
                placeholder={localize.getText("placeholderField", {
                  fieldName: localize.getText("location"),
                })}
                onChange={(value) => handleChangeForm("locationId", value)}
                value={dataFormNew.locationId}
                labelKey="name"
                valueKey="id"
              />
            </div>
          </div>
          <Tabs
            items={[
              {
                label: "Pasien Lama",
                key: "1",
                children: (
                  <div className="d-flex flex-column gap-4">
                    <ExistingPatient
                      columnsExisting={columnsExisting}
                      selectedExistingPatient={selectedExistingPatient}
                      setSelectedExistingPatient={setSelectedExistingPatient}
                    />
                    <ActionAddReservation
                      handleClickSave={handleSubmitExisting}
                      disableSave={!selectedExistingPatient?.id}
                      disableStartVisit={
                        !selectedExistingPatient?.id ||
                        dataFormNew.timeVisit.length === 0
                      }
                    />
                  </div>
                ),
              },
              {
                label: "Pasien Baru",
                key: "2",
                children: (
                  <Form
                    initialValues={{ ...dataFormNew }}
                    validate={validate}
                    onSubmit={handleSubmitNew}
                  >
                    {({ handleSubmit, values, valid }) => (
                      <form
                        className="row row-gap-4"
                        style={{ rowGap: 12 }}
                        onSubmit={handleSubmit}
                      >
                        <FormNewPatient
                          value={dataFormNew}
                          handleChangeForm={handleChangeForm}
                          dataServices={dataServices}
                        />
                        <ActionAddReservation
                          isNew
                          disableStartVisit={
                            !valid || dataFormNew.timeVisit.length === 0
                          }
                        />
                      </form>
                    )}
                  </Form>
                ),
              },
            ]}
          />
        </div>
      </Spinner>
    </Modal>
  );
}
